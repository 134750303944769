<template>
  <el-dialog title="编辑使用方联系方式" :visible.sync="show" width="500px" :close-on-click-modal="false" :destroy-on-close="true" @close="close" >
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm" label-position="left" >
      <el-form-item label="公司名称">
       <el-input v-model="form.company_name" placeholder="请输入公司名称" :maxlength="16" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码">
       <el-input v-model="form.company_code" placeholder="统一社会信用代码" ></el-input>
      </el-form-item>
      <el-form-item label="联系人名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入联系人名称" :maxlength="16" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请输入联系电话" :maxlength="11" show-word-limit></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="submit('ruleForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      form: {
        name: "", //名字
        mobile: "", //电话
        company_name: "",
        company_code: ''//统一社会信用代码
      },
      rules: {
        name: [
          { required: true, message: "请填写联系人姓名", trigger: "change" },
        ],
        mobile: [
          { required: true, message: "请填写联系电话", trigger: "change" },
        ],
      },
      loading: false,
      config: {},
      callback: null,
    };
  },
  methods: {
    close() {
      this.form = this.$options.data().form;
    },
    open(config, callback) {
      console.log(config);
      this.show = true;
      this.form = { ...config.form };
      this.callback = callback;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.callback && this.callback(this.form);
          this.show = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>