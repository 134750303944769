<template>
  <el-container style="height: 100vh;background: #fff;" v-loading="loading" element-loading-text="订单生成中…" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-header height="auto" style="padding: 0 55px;box-sizing: border-box">
      <div class="top-nav flexBt flexCt">
        <div>提交订单</div>
        <div @click="$router.back()">
          返回
          <i class="fa fa-mail-reply"></i>
        </div>
      </div>
    </el-header>
    <el-main style="height: 100%">
      <div class="container-box">
        <el-alert title="温馨提示：提交订单后请尽快支付，支付后平台确认通过后生效。" style="margin-bottom: 40px;" show-icon :closable="false" type="warning"> </el-alert>
        <div class="pub-title" style="margin-bottom: 20px;">
          商品明细
        </div>
        <el-table :header-cell-style="styleObj" :data="order_product_sku" style="width: 100%">
          <el-table-column width="150">
            <template slot-scope="{ row }">
              <div :class="['select-item', { 'select-item-active': row.checked }]" @click="selectReGoods(row)">
                <el-image style="width: 100%; height: 100%; border-radius: 5px" :src="row.product && row.product.master_file && row.product.master_file.image_url">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <div class="chekbox-box" v-show="row.checked">
                  <i class="el-icon-check"></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="160">
            <template slot="header">
              <p>商品信息</p>
            </template>
            <template slot-scope="{ row }">
              <div class="p-title">{{ row.product && row.product.name }}</div>
              <div class="othenr">
                商品编码：{{ row.product && row.product.serial_number }}
              </div>
              <div class="othenr">商品sku：{{ row.sku_number }}</div>
            </template>
          </el-table-column>
          <el-table-column min-width="160">
            <template slot="header">
              <p>商品副标题</p>
            </template>
            <template slot-scope="{ row }">
              <p class="ellipsisText3" :title="(row.product && row.product.subject) || '--'">
                {{ row.product && row.product.subject }}
              </p>
            </template>
          </el-table-column>
          <el-table-column min-width="140">
            <template slot="header">
              <p>有效期（天）</p>
            </template>
            <template slot-scope="{ row }">
              <p>{{ row.validity }}天</p>
            </template>
          </el-table-column>

          <el-table-column min-width="100">
            <template slot="header">
              <p>单价</p>
            </template>
            <template slot-scope="{ row }">
              <p style="font-weight: 550;">￥{{ row.sale_price }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="100">
            <template slot="header">
              <p>优惠金额</p>
            </template>
            <template slot-scope="{ row }">
              <p>￥0.00</p>
            </template>
          </el-table-column> -->
          <el-table-column min-width="200">
            <template slot="header">
              <p>应用计划开始时间</p>
              <p>应用计划结束时间</p>
            </template>
            <template slot-scope="scope">
              <el-date-picker format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" v-if="scope.row.type == 1" @change="changeTime($event, scope.row)" v-model="scope.row.app_start_at" type="datetime" placeholder="选择日期时间" style="width: 220px">
              </el-date-picker>
              <div v-else>
                <p>{{ scope.row.app_start_at || "--" }}</p>
              </div>
              <p>{{ scope.row.app_end_at || "--" }}</p>
            </template>
          </el-table-column>
          <el-table-column min-width="100">
            <template slot="header">
              <p>小计</p>
            </template>
            <template slot-scope="{ row }">
              <p style="color: #F26161;font-weight: bold;">￥{{ row.sale_price }}</p>
            </template>
          </el-table-column>
          <el-table-column min-width="100" fixed="right">
            <template slot="header">
              <p>操作</p>
            </template>
            <template slot-scope="scope">
              <template v-if="scope.row.type === 1">
                <el-button type="text" @click="saveRowEvent(scope.row, scope.$index)">确认</el-button>
                <el-button type="text" @click="cancelRowEvent(scope.row,scope.$index)">取消</el-button>
              </template>
              <template v-else>
                <el-button type="text" @click="editRowEvent(scope.row,scope.$index)">编辑</el-button>
                <el-button type="text" @click="deltRow(scope.$index)">删除</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <div class="pub-title" style="margin: 40px 0 20px 0;">
          订单备注
        </div>
        <div class="desc">
          <el-input type="textarea" style="width: 60%;min-width: 200px;" :autosize="{ minRows: 5, maxRows: 5}" placeholder="请输入内容" maxlength="200" show-word-limit resize="none" v-model="desc">
          </el-input>
        </div>
        <div class="pub-title" style="margin: 40px 0 32px 0;">
          联系方式
        </div>
        <div class="concat-list">
          <div>
            <div class="c-type">销售方</div>
            <p class="company-name">公司名称：{{ seller.company_name || "--" }}</p>
            <p class="pub-text" style="padding-bottom: 9px">
              联系人：{{ seller.name || "--" }}
              <!-- <i class="el-icon-edit-outline empha"></i> -->
            </p>
            <p class="pub-text">联系电话：{{ seller.mobile || "--" }}</p>
          </div>
          <div>
            <div class="c-type">
              采购方
              <!-- <i class="el-icon-edit-outline empha" @click="editSeller"></i> -->
            </div>
            <p class="company-name">公司名称：{{ buyer.company_name || "--" }}</p>
            <p class="pub-text" style="padding-bottom: 9px">
              联系人：{{ buyer.name || "--" }}
              <i class="el-icon-edit-outline empha" @click="editSeller"></i>
            </p>
            <p class="pub-text">联系电话：{{ buyer.mobile || "--" }}</p>
          </div>
          <div>
            <div class="c-type">
              使用方
              <i class="el-icon-edit-outline empha" @click="editUse"></i>
            </div>
            <p class="company-name">公司名称：{{ use.company_name || "--" }}</p>
            <p class="pub-text" style="padding-bottom: 9px">
              联系人：{{ use.name || "--" }}
            </p>
            <p class="pub-text">联系电话：{{ use.mobile || "--" }}</p>
          </div>

        </div>
      </div>

    </el-main>
    <el-footer>
      <el-row>
        <el-col :span="24" align="right">
          <div class="flex-bb">
            <div>
              <div class="order-money">
                支付金额：
                <span class="money-style">
                  ￥{{payTotalMoney}}
                </span>
              </div>
              <el-button type="text" v-for="(item,index) in agList" :key="index" @click="getDetail(item)">《{{item.title}}》<span v-if="index != agList.length && index != 0">、</span></el-button>
            </div>
            <div class="submit-btn" @click="submit">提交订单</div>
          </div>
        </el-col>
      </el-row>
    </el-footer>
    <EditUse ref="editUse" />
    <fw-chosePeopleSearch ref="fw_chosePeopleSearch" />
    <!--采购方-->
    <EditSeller ref="editSeller" />
  </el-container>
</template>
<script>
import { buyer_create } from "@/assets/api/detailsApi.js";
import EditUse from "./components/editUsePop.vue";
import EditSeller from "./components/editSeller.vue";

export default {
  components: { EditUse, EditSeller },
  data() {
    return {
      styleObj: {
        color: "#333",
        backgroundColor: "#f5f5f5",
      },
      seller: {
        company_name: "",
        mobile: "",
        name: "",
        company_code: "",
        serial_number: "",
      }, //销售方
      buyer: {
        ompany_name: "",
        mobile: "",
        name: "",
        company_code: "",
        serial_number: "",
      }, //采购方
      use: {
        ompany_name: "",
        mobile: "",
        name: "",
        company_code: "",
        serial_number: "",
      }, //使用方
      desc: "",
      order_product_sku: [],
      loading: false,
      agList: [],
    };
  },
  computed: {
    //支付金额
    payTotalMoney() {
      if (this.order_product_sku && this.order_product_sku.length !== 0) {
        let rs = this.order_product_sku.reduce((total, item) => {
          let price = 0;
          if (item.sale_price) {
            price = this.numTo(item.sale_price);
          }
          return total + price;
        }, 0);
        rs = (rs / 100).toFixed(2);
        return rs;
      } else {
        return "--";
      }
    },
  },
  mounted() {
    let data = JSON.parse(localStorage.getItem("orderData"));
    const { contact, order_product_sku } = data;
    this.seller = { ...contact.seller };
    this.buyer = { ...contact.buyer };
    this.use = { ...contact.use };
    this.order_product_sku = order_product_sku.map((item) => {
      item.type = 1;
      item.app_start_at = "";
      item.app_end_at = "";
      return item;
    });
    this.getArg();
  },
  methods: {
    //查看协议详情
    getDetail(item) {
      let routeData = this.$router.resolve({
        path: "/shopStore/submitOrder/agree",
        query: { id: item.id },
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   path: '/shopStore/submitOrder/agree',
      //   query:{
      //     id: item.id
      //   }
      // })
    },
    //获取协议
    getArg() {
      this.$axios.get("/setting/agreement/app_shop").then((res) => {
        if (res.code === 200) {
          this.agList = res.data;
        }
      });
    },
    //编辑采购方
    editSeller() {
      this.$refs.editSeller.open(
        {
          form: this.buyer,
        },
        (val) => {
          this.buyer.name = val.name;
          this.buyer.mobile = val.mobile;
          console.log(val);
        }
      );
    },
    //修改使用方
    editUse() {
      this.$refs.editUse.open(
        {
          form: this.use,
        },
        (val) => {
          this.use = val;
        }
      );
    },
    //确定修改
    saveRowEvent(row, index) {
      if (!row.app_start_at) {
        return this.$message.error("请选择应用计划开始时间!");
      }
      row.type = 2;
      console.log(row);
    },
    //编辑
    editRowEvent(row) {
      let msg = true;
      this.order_product_sku.forEach((res) => {
        if (res.type == 1) {
          msg = false;
        }
      });
      if (msg) {
        row.type = 1;
        row.cloneObj = JSON.parse(JSON.stringify(row));
      } else {
        this.$message.error("请先保存修改的内容在编辑其他项目");
      }
    },
    //取消
    cancelRowEvent(row, index) {
      console.log(row, "==");
      if (row.app_start_at == "" && !row.id) {
        this.order_product_sku.splice(index, 1);
      } else {
        let { app_start_at, app_end_at } = row.cloneObj;
        row.app_start_at = app_start_at;
        row.app_end_at = app_end_at;
        row.type = 2;
        console.log(row);
      }
    },
    //修改时间
    //修改时间
    changeTime(e, row) {
      console.log(e, row, "=");
      if (e) {
        console.log(e, row, "=");
        row.app_end_at = this.getNewData(e, row.validity);
      } else {
        row.app_end_at = "";
      }

      console.log(this.getNewData(e, row.validity), "======");
    },
    //计算应用结束时间
    getNewData(date, days) {
      let d = days * 24 * 60 * 60 * 1000;

      let time = Date.parse(new Date(date)) + d;
      let rDate = new Date(time);
      let year = rDate.getFullYear();
      let month = rDate.getMonth() + 1;
      let day = rDate.getDate();

      month = month < 10 ? `0${month}` : month;
      day = day < 10 ? `0${day}` : day;
      let hh = rDate.getHours();
      let mm = rDate.getMinutes();
      let ss = rDate.getSeconds();
      hh = hh < 10 ? `0${hh}` : hh;
      mm = mm < 10 ? `0${mm}` : mm;
      ss = ss < 10 ? `0${ss}` : ss;
      return `${year}-${month}-${day} ${hh}:${mm}:${ss}`;
    },
    numTo(val) {
      return val * 100;
    },
    //编辑供应商联系人
    selectBuyer() {
      this.$refs.fw_chosePeopleSearch.open(
        {
          department_id: this.buyer.company_id, // 传了这个后，就部门选择部门了，默认就是部门里面的人
          titles: "编辑",
          choseData: [], // 已选择的数据。
          type: "radio", // 类型为单选radio,checkbox,
          contact_type: "0", //0.只能内部，不能切换 1.内部，2.外部,3 只能外部，并不能切换
          out: "radio", //强制外部只能单选
          defaultSearch: {}, // 默认参数
          isDepV2: false, // 以部门为力度展示
        },
        (val) => {
          let rs = val.list?.choseMsg;
          this.buyer.name = rs.name;
          this.buyer.mobile = rs.mobile;
          console.log(val);
        }
      );
    },
    //提交订单
    submit() {
      let query = {
        desc: this.desc,
        total_payment: this.payTotalMoney,
        total_price: this.payTotalMoney,
        total_pre: "0.00",
        order_product_sku: [],
        contact: {
          use: this.use,
        },
      };
      if (this.order_product_sku && this.order_product_sku.length !== 0) {
        let tag = this.order_product_sku.some((item) => item.type === 1);
        if (tag) return this.$message.error("请确认所有商品信息！");
        query.order_product_sku = this.order_product_sku.map((item) => {
          return {
            product_sku_id: item.product_sku_id,
            pre_price: "0.00",
            total_price: item.sale_price,
            app_start_at: item.app_start_at,
          };
        });
      }
      this.loading = true;
      buyer_create(query)
        .then((res) => {
          if (res.code === 200) {
            let domin =
              process.env.NODE_ENV == "development"
                ? "https://aa.testfw.cn"
                : location.origin;
            let data = {
              token: sessionStorage.getItem("AccessToken"),
              domin: domin,
              order_id: [res.data.id],
              order_type: "app_shop",
            };
            console.log(data, "支付阐述=========");
            location.href = `https://pay.feiwin.cn/?data=${JSON.stringify(
              data
            )}`;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
      console.log(query, "===");
    },
  },
};
</script>
<style lang="scss" scoped>
.container-box {
  padding: 40px 64px 0 64px;
  box-sizing: border-box;
}
.pub-title {
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: SourceHanSansSC-bold;
  display: flex;
  align-items: center;
  //   border-left: 2px solid #4387F8;
}
.pub-title::before {
  width: 4px;
  height: 16px;
  content: "";
  display: inline-block;
  background: #4387f8;
  margin-right: 8px;
}
.top-nav {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  & > div:nth-child(1) {
    font-weight: 800;
    font-size: 17px;
  }
  & > div:nth-child(2) {
    color: #0088fe;
    cursor: pointer;
  }
}
.desc {
  // padding-left: 100px;
  box-sizing: border-box;
}
.concat-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  & > div {
    width: 404px;
    height: 137px;
    background: #fafafa;
    margin-right: 40px;
    margin-bottom: 19px;
    padding: 20px 20px 18px 44px;
    box-sizing: border-box;
    .c-type {
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
    }
    .company-name {
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      padding: 13px 0 11px;
    }
    .pub-text {
      color: rgba(153, 153, 153, 1);
      font-size: 12px;
      text-align: left;
    }
  }
}
.order-money {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.order-money::before {
  content: "";
  height: 1px;
  border-bottom: 1px solid #cccccc;
  width: 100px;
}
.money-style {
  color: #f75d5d;
  font-weight: bold;
  font-size: 18px;
}
.submit-btn {
  width: 226px;
  height: 40px;
  line-height: 23px;
  border-radius: 3px;
  background-color: rgba(247, 72, 72, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  font-family: Roboto;
  margin-left: 60px;
  cursor: pointer;
}
.ellipsisText3 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3; // 设置两行文字溢出
  -webkit-box-orient: vertical;
}
.flex-bb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>