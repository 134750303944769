import axios from "@/server/ajax.js";
import store from "@/store/index.js";
const platform = store.state.platform; // 1为平台，2为租户

//获取商品详情-新
export function get_product_detail(id) {
  return axios.get(`/app_shop/public/plat_home/get_product_detail/${id}`);
}     

//获取同类目推荐商品-新

export function get_alike_product(params) {
  return axios.postJSON("/app_shop/public/plat_home/get_alike_product", params);
} 

//提交订单页面(提交订单后，生成订单并返回订单信息)
export function view_order(params) {
  return axios.postJSON("/app_shop/order/view_order", params);
} 

//提交订单
export function buyer_create(params) {
  return axios.postJSON("/app_shop/order/buyer_create", params);
}

//获取登录信息
export function login_info(id) {
  return axios.get(`base/user/login_info`);
} 


// 获取商品-详情-同类推荐商品
export function get_allspu_list(params) {
  if (platform == 1) {
    return axios.postJSON("/shop/public/cust_home/get_allspu_list", params);
  } else {
    return axios.postJSON("/shop/public/cust_home/get_allspu_list", params);
  }
}
// 获取 配送范围
export function get_address(params) {
  if (platform == 1) {
    return axios.get("/city/list/all");
  } else {
    return axios.get("/city/list/all");
  }
}
// 获取 二维码
export function get_generate_qrcode(params) {
  if (platform == 1) {
    return axios.postJSON("/api/ocr/generate_qrcode", params);
  } else {
    return axios.postJSON("/api/ocr/generate_qrcode", params);
  }
}

// 企业购物车
export function post_buy_cart(params) {
  if (platform == 1) {
    return axios.postJSON("/shop/public/common/buy_cart/create", params);
  } else {
    return axios.postJSON("/shop/public/common/buy_cart/create", params);
  }
}
// 个人购物车
export function post_buyer_cart(params) {
  if (platform == 1) {
    return axios.postJSON("/shop/public/common/buyer_cart/create", params);
  } else {
    return axios.postJSON("/shop/public/common/buyer_cart/create", params);
  }
}

// 二维码
export function generate_qrcode(params) {
  if (platform == 1) {
    return axios.post("/ocr/generate_qrcode", params);
  } else {
    return axios.post("/ocr/generate_qrcode", params);
  }
}


